import React, { useState } from 'react'
import './order-card.css'
import MainButton from '../utils/mainButton/MainButton'
import { ReactComponent as DropdownIcon } from '../../assets/dropdown_icon_white.svg'
import OrderDetail from '../OrderDetail/OrderDetail'
import { formatDate } from '../../services/filterService'
import { type OrderType, type ProductType } from '../../utils/types'
import imageService from '../../services/imageService'
import MacaronPoints from '../utils/macaronPoints/macaronPoints'

interface OrderCardprops {
  order: OrderType
}

const OrderCard: React.FC<OrderCardprops> = (props) => {
  const [showOrderDetail, setShowOrderDetail] = useState(false)

  const orderCardContainerClassName = `order-card-container font__body--medium${showOrderDetail ? ' is-active' : ''}`
  const dropdownIconClassName = `order-card-dropdown-icon${showOrderDetail ? ' is-active' : ''}`
  const orderCardImgQuantityClassName = `order-card-img-quantity${showOrderDetail ? ' is-active' : ''}`

  const getTotalQuantity = (products: ProductType[]): number => {
    let total = 0
    products && products.forEach(p => {
      total += p.quantity || 0
    })
    return total
  }

  return (
    <div className={orderCardContainerClassName}>
      <div className='order-card-id-date font__body--bold-medium'>
        Commande n°{props.order.orderKey} du {formatDate(String(props.order.createdAt))}
      </div>
      <div className={orderCardImgQuantityClassName}>
        <img
          src={imageService.getImageUrl(props.order.products[0].imageUrl || '')}
          className='order-card-img'
          alt='Image produit'
        />
        <div className='order-card-quantity font__body--bold-medium'>
          {getTotalQuantity(props.order.products)} Références
        </div>
        <MacaronPoints points={props.order.totalPoints} className='history-macaron' />
      </div>
      {showOrderDetail &&
        <OrderDetail products={props.order.products} />
      }
      <MainButton onClick={() => { setShowOrderDetail(!showOrderDetail) }}>
        <span className={dropdownIconClassName}>
          <DropdownIcon />
        </span>
        Voir le détail
      </MainButton>
    </div>
  )
}

export default OrderCard

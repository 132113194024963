import React from 'react'
import Point1Icon from '../assets/point1_icon.svg'
import Point2Icon from '../assets/point2_icon.svg'
import Point3Icon from '../assets/point3_icon.svg'
import Point4Icon from '../assets/point4_icon.svg'
import Point5Icon from '../assets/point5_icon.svg'
import Point6Icon from '../assets/point6_icon.svg'
import Point7Icon from '../assets/point7_icon.svg'
import Point8Icon from '../assets/point8_icon.svg'
import Point9Icon from '../assets/point9_icon.svg'
import Point10Icon from '../assets/point10_icon.svg'
import { type PackageType, type ProductType } from '../utils/types'

export const getPointsSvg = (pointValue: number | null | undefined): JSX.Element | null => {
  const tab = [
    Point1Icon,
    Point2Icon,
    Point3Icon,
    Point4Icon,
    Point5Icon,
    Point6Icon,
    Point7Icon,
    Point8Icon,
    Point9Icon,
    Point10Icon
  ]
  if (pointValue) {
    return <img src={tab[pointValue - 1]} />
  } else {
    return null
  }
}

export const getProductTotalPoints = (product: ProductType): number | null => {
  const minToWinPoints: number | null = product.minToWinPoints
  const maxToWinPoints: number | null = product.maxToWinPoints
  const pointValue: number | null = product.pointValue
  const quantity: number | undefined = product.quantity

  if (quantity && pointValue) {
    if (minToWinPoints && maxToWinPoints && (minToWinPoints <= quantity && quantity <= maxToWinPoints)) {
      return quantity * pointValue
    } else if (maxToWinPoints && (quantity >= maxToWinPoints)) {
      return maxToWinPoints * pointValue
    } else {
      return null
    }
  } else {
    return null
  }
}

export const getPackageTotalPoints = (pkg: PackageType): number | null => {
  const minToWinPoints: number | null = pkg.minToWinPoints
  const maxToWinPoints: number | null = pkg.maxToWinPoints
  const pointValue: number | null = pkg.pointValue
  const quantity: number | undefined = pkg.quantity

  if (quantity && pointValue) {
    if (minToWinPoints && maxToWinPoints && (minToWinPoints <= quantity && quantity <= maxToWinPoints)) {
      return quantity * pointValue
    } else if (maxToWinPoints && (quantity >= maxToWinPoints)) {
      return maxToWinPoints * pointValue
    } else {
      return null
    }
  } else {
    return null
  }
}

export default { getPointsSvg, getProductTotalPoints, getPackageTotalPoints }

import React, { useEffect, useState, type DragEvent, useMemo } from 'react'
import imageService from '../../services/imageService'
import AdminButton from '../utils/adminButton/AdminButton'
import imageCompression from 'browser-image-compression'
import './images-library.css'
import { ReactComponent as DeleteIcon } from '../../assets/remove_icon.svg'
import { ReactComponent as ImportIcon } from '../../assets/import_icon.svg'
import InfoModal from '../utils/infoModal/InfoModal'
import ConfirmationModal from '../utils/confirmationModal/ConfirmationModal'
import { extractImageNameFromUrl } from '../../utils/utils'
import Spinner from '../utils/spinner/Spinner'

const ImagesLibrary: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<FileList>()
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [fileToDelete, setFileToDelete] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [modalText, setmodalText] = useState('')
  const [isPending, setIsPending] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    void fetchImages()
  }, [])

  const fetchImages = async (): Promise<void> => {
    try {
      const urls = await imageService.listImages()
      const filteredUrls = urls.filter(url => !url.includes('image_placeholder'))
      setImageUrls(filteredUrls)
      setIsLoading(false)
    } catch (error) {
      console.error('Failed to fetch images:', error)
      setIsLoading(false)
    }
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.files) {
      setSelectedFiles(event.target.files)
    }
  }

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()
    let fileFormatCheck = false
    setIsPending(true)

    if (selectedFiles && selectedFiles.length > 0) {
      const uploadPromises = Array.from(selectedFiles).map(async (file) => {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
          fileFormatCheck = true
          return
        }

        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }

        try {
          const compressedFile = await imageCompression(file, options)
          await imageService.uploadImage(compressedFile)
        } catch (error) {
          setIsPending(false)
          console.error('Error while compressing/uploading the image:', error)
        }
      })

      await Promise.all(uploadPromises).then(() => {
        setIsPending(false)
      })

      if (fileFormatCheck) {
        setIsPending(false)
        setmodalText("Le type de l'image importée n'est pas conforme.")
        setIsModalOpen(true)
      } else {
        setIsPending(false)
        setmodalText('Image(s) importée(s) avec succès')
        setIsModalOpen(true)
        setSelectedFiles(undefined)
        setIsLoading(true)
        await fetchImages()
      }
    } else {
      console.error('No files selected')
      setIsPending(false)
    }
  }

  const memoImageUrls = useMemo(() => {
    return imageUrls.map(key => imageService.getImageUrl(key))
  }, [imageUrls])

  const handleDeleteImage = async (imageUrl: string): Promise<void> => {
    const filename = imageUrl.split('/').pop()
    if (filename) {
      try {
        await imageService.deleteImage(filename)
        setIsLoading(true)
        await fetchImages()
      } catch (error) {
        console.error('Failed to delete image:', error)
      }
    }
  }

  const onDrop = (e: DragEvent<HTMLLabelElement>): void => {
    e.preventDefault()
    const { files } = e.dataTransfer
    if (files.length > 0) {
      setSelectedFiles(files)
    }
  }

  const onDragOver = (e: DragEvent<HTMLLabelElement>): void => {
    e.preventDefault()
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '100px'
      }}
    >
      <form
        style={{
          width: '100%',
          position: 'relative'
        }}
        onSubmit={handleSubmit}
      >
        <label
          className={`label ${
            selectedFiles ? 'border-green-500' : 'border-gray-300'
          }`}
          htmlFor='file_upload'
          onDragOver={onDragOver}
          onDrop={onDrop}
        >
          <span className='span-container'>
            <ImportIcon
              className={`icon ${
                selectedFiles ? 'text-green-600' : 'text-gray-600'
              }`}
            />
            <span
              className={`font-medium ${
                selectedFiles ? 'text-green-600' : 'text-gray-600'
              }`}
            >
              {selectedFiles
                ? Array.from(selectedFiles)
                  .map((file) => file.name)
                  .join(', ')
                : 'Importer des Images'}
            </span>
          </span>
          <input
            className='hidden'
            name='file_upload'
            onChange={handleFileChange}
            type='file'
            id='file_upload'
            accept='image/jpeg, image/png'
            multiple
          />
        </label>
        <AdminButton type='submit' className='import-button'>
        <div className="confirmation-button-wrapper">
            {isPending && <Spinner />}
            <span>{!isPending ? 'IMPORTER' : 'Import en cours...' }</span>
          </div>
        </AdminButton>
      </form>
      {isLoading
        ? (<Spinner />)
        : (
          <div className='image-library-container'>
            {memoImageUrls.map((url) => (
              <div key={url} className='library-image-card-container'>
              <div className='library-image-card'>
                <img
                  src={url}
                  alt='Uploaded'
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                  className='library-image'
                />
                <DeleteIcon
                  style={{
                    position: 'absolute',
                    top: 4,
                    right: 2,
                    cursor: 'pointer'
                  }}
                  onClick={async () => {
                    setFileToDelete(url)
                    setIsConfirmationModalOpen(true)
                  }}
                >
                  Delete
                </DeleteIcon>
              </div>
              <p className='font__body--bold-medium'>{extractImageNameFromUrl(url)}</p>
              </div>
            ))}
          </div>
          )}

      <InfoModal
        isOpen={isModalOpen}
        title='Import Images'
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title='Supprimer une image'
        message='Voulez-vous supprimer cette image ?'
        onClose={() => {
          setIsConfirmationModalOpen(false)
        }}
        onConfirme={async () => {
          await handleDeleteImage(fileToDelete)
          setIsConfirmationModalOpen(false)
        }}
      ></ConfirmationModal>
    </div>
  )
}

export default ImagesLibrary

import React, { useState } from 'react'
import './catalog-product-list.css'
import { type PackageType, type ProductType } from '../../../utils/types'
import CatalogProductCard from '../catalogProductCard/CatalogProductCard'
import CatalogPackageCard from '../catalogPackageCard/CatalogPackageCard'

interface CatalogProductListProps {
  catalogItems: Array<PackageType | ProductType>
}

const CatalogProductList: React.FC<CatalogProductListProps> = ({
  catalogItems
}) => {
  const [expandedItemId, setExpandedItemId] = useState<number | null>(null)
  const [isClosing, setIsClosing] = useState(false)
  const transitionDuration = 500

  const handleExpand = (id: number): void => {
    if (expandedItemId === id) {
      // If the same item is clicked again, collapse it
      setExpandedItemId(null)
    } else {
      if (expandedItemId !== null) {
        // If there's an already expanded item, close it first
        setIsClosing(true)
        setTimeout(() => {
          setIsClosing(false)
          setExpandedItemId(id) // Open the new item after the previous one has fully closed
        }, transitionDuration)
      } else {
        // If no item is currently expanded, open the new one immediately
        setExpandedItemId(id)
      }
    }
  }

  return (
    <div className='product-list-container'>
      {catalogItems.map((item) =>
        item.type === 'package' ? (
          <CatalogPackageCard
            key={item.id}
            pkg={item as PackageType}
            isExpanded={expandedItemId === item.id && !isClosing} // Pass the expanded state
            onExpand={() => { handleExpand(item.id) }} // Handle click to expand/collapse
          />
        ) : (
          <CatalogProductCard
            key={item.id}
            product={item as ProductType}
            isExpanded={expandedItemId === item.id && !isClosing} // Pass the expanded state
            onExpand={() => { handleExpand(item.id) }} // Handle click to expand/collapse
          />
        )
      )}
    </div>
  )
}

export default CatalogProductList

import React, { type ButtonHTMLAttributes, useState, useEffect } from 'react'
import { type PackageType } from '../../../utils/types'
import { ReactComponent as BinIcon } from '../../../assets/bin_white.svg'
import { ReactComponent as PlusIcon } from '../../../assets/plus_white.svg'
import { ReactComponent as MinusIcon } from '../../../assets/less_white.svg'
import './handle-package-cart-button.css'
import { useAppDispatch } from '../../../utils/hooks'
import { clearPackagesCart, setLessPackageQuantity, setMorePackageQuantity, setUpdatedPackageQuantity } from '../../../slices/cartSlice'

interface HandleCartButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  pkg: PackageType
  getPackageQuantity: () => number
}

const HandleCartButton: React.FC<HandleCartButtonProps> = ({ pkg, getPackageQuantity }) => {
  const dispatch = useAppDispatch()
  const quantityValue = getPackageQuantity()
  const [isLessButtonDisabled, setIsLessButtonDisabled] = useState(true)
  const [isMoreButtonDisabled, setIsMoreButtonDisabled] = useState(!(pkg.minToOrder && pkg.maxToOrder && pkg.maxToOrder > pkg.minToOrder))

  useEffect(() => {
    checkDisabledButtons()
  }, [getPackageQuantity])

  const checkDisabledButtons: () => void = () => {
    (!pkg.minToOrder && getPackageQuantity() <= 1) || (pkg.minToOrder && getPackageQuantity() <= pkg.minToOrder) ? setIsLessButtonDisabled(true) : setIsLessButtonDisabled(false)
    pkg.maxToOrder && getPackageQuantity() >= pkg.maxToOrder ? setIsMoreButtonDisabled(true) : setIsMoreButtonDisabled(false)
  }

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newQuantityValue = parseInt(e.target.value, 10)
    dispatch(setUpdatedPackageQuantity({ pkg, quantity: newQuantityValue }))
  }

  // Check if user input exceed min max quantity limit
  const handleBlur = (): void => {
    let newQuantity = quantityValue
    if (pkg.maxToOrder && quantityValue > pkg.maxToOrder) {
      newQuantity = pkg.maxToOrder
    }
    if (pkg.minToOrder && quantityValue < pkg.minToOrder) {
      newQuantity = pkg.minToOrder
    }
    dispatch(setUpdatedPackageQuantity({ pkg, quantity: newQuantity }))
  }

  return (
    <div className='addToCart add-to-cart-package'>
      <button className={'addToCart__buttons addToCart__buttons--bin'}
        onClick={() => { dispatch(clearPackagesCart(pkg)) }}>
        <BinIcon />
      </button>

      <button
        className={`addToCart__buttons addToCart__buttons--quantity ${isLessButtonDisabled ? 'addToCart__buttons--disabled' : ''}`}
        onClick={isLessButtonDisabled ? undefined : () => dispatch(setLessPackageQuantity(pkg))}
      >
        <MinusIcon />
      </button>

      <input
        type="number"
        className='addToCart__input'
        value={quantityValue}
        onChange={handleQuantityChange}
        onBlur={handleBlur}
        min={pkg.minToOrder !== null ? pkg.minToOrder : undefined}
        max={pkg.maxToOrder !== null ? pkg.maxToOrder : undefined}
      />

      <button
        className={`addToCart__buttons addToCart__buttons--quantity ${isMoreButtonDisabled ? 'addToCart__buttons--disabled' : ''}`}
        onClick={isMoreButtonDisabled ? undefined : () => dispatch(setMorePackageQuantity(pkg))}
      >
        <PlusIcon />
      </button>
    </div>
  )
}

export default HandleCartButton

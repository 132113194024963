import React, { useEffect, useState } from 'react'
import './info-panel.css'
import { ReactComponent as CloseIcon } from '../../../assets/close_black.svg'
import { ReactComponent as InfoIcon } from '../../../assets/question_mark.svg'
import { useAppSelector } from '../../../utils/hooks'
import { type ProductType } from '../../../utils/types'
import { useSelector } from 'react-redux'

interface PanelInfoProps {
  isInProductCatalog?: boolean
  panelDisplay: boolean
  setPanelDisplay: React.Dispatch<React.SetStateAction<boolean>>
}

const InfoPanel: React.FC<PanelInfoProps> = (props) => {
  const [hasPromotionProducts, setHasPromotionProducts] = useState<boolean>(false)
  const audienceProducts = useSelector((state: any) => state.product.productList)
  const {
    isInProductCatalog = false,
    panelDisplay,
    setPanelDisplay
  } = props

  const orderFrequency = useAppSelector(state => state.user?.audience?.frequency)
  const orderFrequencyLabel = orderFrequency === 0 ? '' : orderFrequency
  const infoPanelClass = `info-panel${panelDisplay ? ' is-active' : ''}`
  const infoPanelContainerClass = `info-panel-container ${panelDisplay ? ' is-active' : ''}`

  useEffect(() => {
    const containsPromotionProducts = audienceProducts.find((product: ProductType) => product.pointValue !== null && product.pointValue && product.pointValue > 0)
    setHasPromotionProducts(containsPromotionProducts as boolean)
  })

  return (
    <>
      <span
        className='info-panel-icon'
        onClick={() => { setPanelDisplay(true) }}
      >
        <InfoIcon />
      </span>
      <div className={infoPanelClass}>

        <div className={infoPanelContainerClass}>
          <p className='font__title--h2'> Comment ça marche ?</p>
          <span
            className='close-icon'
            onClick={() => { setPanelDisplay(false) }}
          >
            <CloseIcon />
          </span>
          {isInProductCatalog
            ? <>
              <p className='font__body--medium'>
                Vous avez la possibilité de passer <b className='font__body--bold-medium'>une commande additionnelle tous
                  les {orderFrequencyLabel === 1 ? '' : orderFrequencyLabel} jours</b> qui sera livrée sur votre prochaine commande cadencée, en fonction
                de votre calendrier de commande.
              </p>
              <p className='font__body--medium'>
                <b className='font__body--bold-medium' style={{ color: 'var(--main-orange)' }}>1 -</b> Sélectionnez les
                quantités à commander par produit.
              </p>
              <p className='font__body--medium'>
                <b className='font__body--bold-medium' style={{ color: 'var(--main-orange)' }}>2 -</b> Validez votre commande en cliquant sur le bouton &quot;Mon Panier&quot;.
              </p>
              {hasPromotionProducts &&
              <>
              <p className='font__body--bold-medium'>
                N&apos;oubliez pas de profiter de nos offres du mois pour accéder quotidiennement au Ticket d&apos;or et cumulez des points ! *
              </p>
              <p className='font__body--medium'>
                <b className='font__body--bold-medium'>* </b>
                Vous pourrez accéder au Ticket d&apos;Or quotidien dès le 1er jour ouvré du mois prochain, jusqu&apos;au dernier jour du mois concerné.
              </p>
              </>
              }
            </>
            : <>
              <p className='font__body--bold-medium'>
                <b style={{ color: 'var(--main-orange)' }}>1 -</b> Vérifiez votre commande.
              </p>
              <p className='font__body--bold-medium'>
                <b style={{ color: 'var(--main-orange)' }}>2 -</b> Cochez la case &quot;Bon pour accord&quot;.
              </p>
              <p className='font__body--bold-medium'>
                <b style={{ color: 'var(--main-orange)' }}>3 -</b> Appuyez sur &quot;Valider&quot; pour transmettre votre commande à Logista.
              </p>
              <p className='font__body--bold-medium'>
                <b style={{ color: 'var(--main-orange)' }}>4 -</b> Vous recevrez une confirmation de votre commande par mail et Philip Morris France SAS
                transmettra celle-ci à Logista afin qu’elle soit intégrée à votre prochaine commande cadencée
                (sous réserve que celle-ci ait lieu au moins 4 jours avant cette livraison, dans le cas contraire votre commande sera intégrée à
                la livraison suivante).
              </p>
              <p className='font__body--medium'>
                Les prix facturés sont ceux en vigueur à la date de livraison et seul Logista est en mesure de vous les communiquer.
                Pour toute question relative à votre commande, merci de vous référer aux Conditions Générales de Vente de Logista.
              </p>
              <p className='font__body--medium'>
                Philip Morris France SAS ne saurait être tenu responsable d’un éventuel retard de livraison ou d’une erreur de commande.
              </p>
              <p className='font__body--medium'>
                Philip Morris France SAS se réserve le droit de suspendre ou modifier toute &quot;offre du mois&quot; durant le mois en cours.
              </p>
            </>
          }
                        <p className='font__body--medium'>
                Les produits commandés via cet outil ne peuvent pas être repris sous le process de reprise Phillip Morris France.
              </p>
                        <p className='font__body--medium'>
                Toute commande comprenant un produit offre du mois ne pourra être annulée.
              </p>
        </div>
      </div>
    </>
  )
}

export default InfoPanel

import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type PackageType } from '../utils/types'

interface PackageListState {
  packageList: PackageType[]
}

const initialState: PackageListState = {
  packageList: []
}

const packageListSlice = createSlice({
  name: 'packageList',
  initialState,
  reducers: {
    updatePackageList: (state, action: PayloadAction<PackageType[]>) => {
      state.packageList = action.payload
    }
  }
})
export const { updatePackageList } = packageListSlice.actions
export default packageListSlice.reducer

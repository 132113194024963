import React, { useRef, useEffect, useState } from 'react'
import './catalog-product-card.css'
import MainButton from '../../utils/mainButton/MainButton'
import HandleCartButton from '../../utils/handleCartButton/HandleCartButton'
import { type ProductType } from '../../../utils/types'
import { ReactComponent as DropdownIcon } from '../../../assets/dropdown_icon.svg'
import { addToCart } from '../../../slices/cartSlice'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { getPointsSvg } from '../../../services/pointsService'
import imageService from '../../../services/imageService'
import GoldenTickerImg from '../../../assets/golden-ticket.png'
import MacaronPoints from '../../utils/macaronPoints/macaronPoints'

interface CatalogProductCardProps {
  product: ProductType
  isExpanded: boolean // Add prop to control expansion
  onExpand: () => void // Add prop to handle expand/collapse
}

const CatalogProductCard: React.FC<CatalogProductCardProps> = ({
  product,
  onExpand,
  isExpanded
}) => {
  const dispatch = useAppDispatch()
  const contentRef = useRef<HTMLDivElement>(null)
  const productsList = useAppSelector((state) => state.cart.products)
  const [firstWorkingDay, setFirstWorkingDay] = useState('')

  useEffect(() => {
    const getFirstWorkingDayOfNextMonth = (): string => {
      const today = new Date()
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1)

      const firstWorkingDay = nextMonth

      while (firstWorkingDay.getDay() === 0 || firstWorkingDay.getDay() === 6) {
        firstWorkingDay.setDate(firstWorkingDay.getDate() + 1)
      }

      const day = firstWorkingDay.getDate().toString().padStart(2, '0')
      const month = (firstWorkingDay.getMonth() + 1).toString().padStart(2, '0')
      return `${day}/${month}`
    }

    setFirstWorkingDay(getFirstWorkingDayOfNextMonth())
  }, [])

  const generateQuantityText = (product: ProductType): JSX.Element | null => {
    if (product.maxToOrder && product.minToOrder) {
      return (
        <p className='font__body--medium product-description'>
          Pour valider votre panier, la quantité doit être comprise entre{' '}
          {product.minToOrder} et {product.maxToOrder}.
        </p>
      )
    } else if (product.minToOrder && !product.maxToOrder) {
      return (
        <p className='font__body--medium product-description'>
          Pour valider le panier, la quantité minimale à sélectionner est de{' '}
          {product.minToOrder}.
        </p>
      )
    } else if (!product.minToOrder && product.maxToOrder) {
      return (
        <p className='font__body--medium product-description'>
          Pour valider le panier, la quantité maximale à sélectionner est de{' '}
          {product.maxToOrder}.
        </p>
      )
    } else {
      return null
    }
  }
  const generatePointsText = (product: ProductType): JSX.Element | null => {
    if (product.maxToWinPoints && product.minToWinPoints && product.pointValue !== 0) {
      return (
        <div className='points-text-container'>
          {product.pointValue && <MacaronPoints points={product.pointValue} />}
          <div
            className='font__body--bold-small'
            style={{ color: 'var(--error-color)' }}
          >
            Entre {product.minToWinPoints} et {product.maxToWinPoints}{' '}
            quantités, vous pouvez gagner {product.pointValue} points par
            quantité commandée.
          </div>
        </div>
      )
    } else if (!product.maxToWinPoints && product.minToWinPoints) {
      return (
        <div className='points-text-container'>
          {product.pointValue && <div>{getPointsSvg(product.pointValue)}</div>}
          <div
            className='font__body--bold-small'
            style={{ color: 'var(--error-color)' }}
          >
            Plus de {product.minToWinPoints} quantités, vous pouvez gagner{' '}
            {product.pointValue} points par quantité commandée.
          </div>
        </div>
      )
    } else if (product.maxToWinPoints && !product.minToWinPoints) {
      return (
        <div className='points-text-container'>
          {product.pointValue && <div>{getPointsSvg(product.pointValue)}</div>}
          <div
            className='font__body--bold-small'
            style={{ color: 'var(--error-color)' }}
          >
            Moins de {product.maxToWinPoints} quantités, vous pouvez gagner{' '}
            {product.pointValue} par quantité commandée.
          </div>
        </div>
      )
    } else if (product.pointValue === 0) {
      return (
        <div className='points-text-container'>
          <img src={GoldenTickerImg} width={75} height={30} />
          <div
            className='font__body--bold-small'
            style={{ color: '#B8860B' }}
          >
           Débloquez un accès au Ticket d&apos;or quotidien à partir du {firstWorkingDay}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const getProductQuantity: () => any = () => {
    const foundProduct = productsList.find((item) => item.id === product.id)
    return foundProduct ? foundProduct.quantity : 0
  }

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isExpanded
        ? `${contentRef.current.scrollHeight}px`
        : '0px'
    }
  }, [isExpanded])

  return (
    <div
      className={`product-card-container ${isExpanded ? 'highlighted-product' : ''}`}
    >
      <img
        src={imageService.getImageUrl(product.imageUrl || '')}
        alt='Product'
        className='product-catalog-img'
      />
      <div className='font__body--bold-medium product-label'>
        {product.label}
      </div>
      <div className='product-details'>
        <div className='details-dropdown'>
          <div className='details-button' onClick={onExpand}>
            <button className='expand-button'>Détails</button>
            <DropdownIcon className={isExpanded ? 'rotate' : ''} />
          </div>
          <div ref={contentRef} className='content'>
            <div>
              <b className='font__body--bold-medium'>Code Logista: </b>
              <span className='font__body--medium'>
                {product.code.replace(/-00$/, '')}
              </span>
            </div>
            <div className='font__body--semi-bold'>
              {generateQuantityText(product)}
            </div>
            {generatePointsText(product)}
          </div>
        </div>
      </div>
      {getProductQuantity() === 0 ? (
        <MainButton
          className='add-to-card-button'
          onClick={() => {
            dispatch(addToCart(product))
          }}
        >
          Ajouter au panier
        </MainButton>
      ) : (
        <HandleCartButton
          product={product}
          getProductQuantity={getProductQuantity}
        />
      )}
    </div>
  )
}

export default CatalogProductCard

import React, { type ReactElement } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import './const.css'
import AdminPanel from './Pages/adminPanel/AdminPanel'
import ProductCatalog from './Pages/productCatalog/ProductCatalog'
import ForbiddenOrder from './Pages/forbiddenOrder/ForbiddenOrder'
import CartPanel from './Pages/cartPanel/cartPanel'
import OrderConfirmation from './Pages/orderConfirmation/OrderConfirmation'
import OrderList from './Pages/orderList/OrderList'
import AppInitializer from './utils/AppInitializer'

function App (): ReactElement {
  return (
    <Router>
      <AppInitializer>
        <Routes>
          <Route element={<AdminPanel />} path='/admin/*' />
          <Route element={<ProductCatalog />} path='/product-catalog/*' />
          <Route element={<CartPanel />} path='/cart/*' />
          <Route element={<ForbiddenOrder />} path='/forbidden-order/*' />
          <Route element={<OrderConfirmation />} path='/order-confirmation/*' />
          <Route element={<OrderList />} path='/order-list/*' />
        </Routes>
      </AppInitializer>
    </Router>
  )
}

export default App

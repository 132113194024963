import React from 'react'
import './toggle-button.css'

interface ToggleButtonProps {
  enabled: boolean
  onClick: () => void
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ enabled, onClick }) => {
  return (
    <label className='toggle-switch'>
      <input type='checkbox' checked={enabled} onChange={onClick} />
      <span className='switch' />
    </label>
  )
}

export default ToggleButton

import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type PackageType, type ProductType } from '../utils/types'

interface CartState {
  products: ProductType[]
  packages: PackageType[]
}

const initialState: CartState = {
  products: [],
  packages: []
}

const findIndex: (state: any, product: ProductType) => number = (
  state,
  product
) => {
  const i = state.products.findIndex(
    (item: { id: number }) => item.id === product.id
  )
  return i
}
const findPackageIndex: (state: any, pkg: PackageType) => number = (
  state,
  pkg
) => {
  const i = state.packages.findIndex(
    (item: { id: number }) => item.id === pkg.id
  )
  return i
}

const findStateProduct: (state: any, product: ProductType) => ProductType = (
  state,
  product
) => {
  return state.products.find(
    (stateProduct: { id: number }) => stateProduct.id === product.id
  )
}
const findStatePackage: (state: any, pkg: PackageType) => PackageType = (
  state,
  pkg
) => {
  return state.packages.find(
    (statePackage: { id: number }) => statePackage.id === pkg.id
  )
}

const updateQuantity: (product: ProductType, action: string) => void = (
  product: ProductType,
  action: string
) => {
  if (product?.quantity) {
    switch (action) {
      case 'decrease':
        product.quantity--
        break
      case 'increase':
        product.quantity++
        break
    }
  }
}
const updatePackageQuantity: (pkg: PackageType, action: string) => void = (
  pkg: PackageType,
  action: string
) => {
  if (pkg?.quantity) {
    switch (action) {
      case 'decrease':
        pkg.quantity--
        break
      case 'increase':
        pkg.quantity++
        break
    }
  }
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<ProductType>) => {
      const product = action.payload
      state.products.push({
        ...product,
        quantity: product.minToOrder ? product.minToOrder : 1,
        totalPoints: 0
      })
    },
    addToPackageCart: (state, action: PayloadAction<PackageType>) => {
      const pkg = action.payload
      state.packages.push({
        ...pkg,
        quantity: pkg.minToOrder ? pkg.minToOrder : 1,
        totalPoints: 0
      })
    },
    setLessQuantity: (state, action: PayloadAction<ProductType>) => {
      const product = action.payload
      updateQuantity(findStateProduct(state, product), 'decrease')
    },
    setLessPackageQuantity: (state, action: PayloadAction<PackageType>) => {
      const pkg = action.payload
      updatePackageQuantity(findStatePackage(state, pkg), 'decrease')
    },
    setMoreQuantity: (state, action: PayloadAction<ProductType>) => {
      const product = action.payload
      updateQuantity(findStateProduct(state, product), 'increase')
    },
    setMorePackageQuantity: (state, action: PayloadAction<PackageType>) => {
      const pkg = action.payload
      updatePackageQuantity(findStatePackage(state, pkg), 'increase')
    },
    setUpdatedQuantity: (
      state,
      action: PayloadAction<{ product: ProductType, quantity: number }>
    ) => {
      const { product, quantity } = action.payload
      const foundProduct = findStateProduct(state, product)
      if (foundProduct) {
        foundProduct.quantity = quantity
      }
    },
    setUpdatedPackageQuantity: (
      state,
      action: PayloadAction<{ pkg: PackageType, quantity: number }>
    ) => {
      const { pkg, quantity } = action.payload
      const foundPackage = findStatePackage(state, pkg)
      if (foundPackage) {
        foundPackage.quantity = quantity
      }
    },
    clearProductsCart: (state, action: PayloadAction<ProductType>) => {
      const product = action.payload
      const i: number = findIndex(state, product)
      state.products.splice(i, 1)
    },
    clearPackagesCart: (state, action: PayloadAction<PackageType>) => {
      const pkg = action.payload
      const i: number = findPackageIndex(state, pkg)
      state.packages.splice(i, 1)
    },
    updateTotalPoints: (
      state,
      action: PayloadAction<{ product: ProductType, pointsValue: any }>
    ) => {
      const { product, pointsValue } = action.payload
      const stateProduct = findStateProduct(state, product)
      stateProduct.totalPoints = pointsValue
    },
    updateTotalPackagePoints: (
      state,
      action: PayloadAction<{ pkg: PackageType, pointsValue: any }>
    ) => {
      const { pkg, pointsValue } = action.payload
      const statePackage = findStatePackage(state, pkg)
      statePackage.totalPoints = pointsValue
    }
  }
})

export const {
  addToCart,
  setLessQuantity,
  setMoreQuantity,
  setUpdatedQuantity,
  clearProductsCart,
  updateTotalPoints,
  updateTotalPackagePoints,
  clearPackagesCart,
  setUpdatedPackageQuantity,
  setMorePackageQuantity,
  setLessPackageQuantity,
  addToPackageCart
} = cartSlice.actions

export default cartSlice.reducer

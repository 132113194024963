import React, { useState, type ReactElement, useEffect } from 'react'
import AdminButton from '../utils/adminButton/AdminButton'
import Modal from '../utils/modal/Modal'
import './audiences.css'
import AddAudience from './addAudience/AddAudience'
import ListAudience from './listAudience/ListAudience'
import { type AudienceType } from '../../utils/types'
import audienceService from '../../services/audienceService'

const Audiences = (): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [audiences, setAudiences] = useState<AudienceType[]>([])

  const handleAddAudience = (audience: AudienceType): void => {
    const fetchAudiences = async (): Promise<void> => {
      try {
        const response = await audienceService.getAllAudiences()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setAudiences(response)
      } catch (error) {
        console.error('Failed to fetch audiences:', error)
      }
    }
    void fetchAudiences()
  }

  useEffect(() => {
    const fetchAudiences = async (): Promise<void> => {
      try {
        const response = await audienceService.getAllAudiences()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setAudiences(response)
      } catch (error) {
        console.error('Failed to fetch audiences:', error)
      }
    }
    void fetchAudiences()
  }, [])

  return (
    <div className='audiences-container'>
      <AdminButton
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        CREER AUDIENCE
      </AdminButton>
      <ListAudience audiencesList={audiences} />
      <Modal
        isOpen={isModalOpen}
        title='Ajouter une nouvelle audience'
        onClose={() => {
          setIsModalOpen(false)
        }}
      >
        <AddAudience
        audiences = {audiences}
          closeModal={() => {
            setIsModalOpen(false)
          }}
          onAddAudience={handleAddAudience}
        />
      </Modal>
    </div>
  )
}

export default Audiences

import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type UserType } from '../utils/types'

const initialState: UserType = {
  id: '',
  accountNumber: '',
  audience: {
    id: 0,
    name: '',
    frequency: null,
    minOrder: null,
    maxOrder: null
  },
  contactId: '',
  touchPoint: '',
  accoundEmail: '',
  accoundLastName: '',
  accoundFirstName: '',
  shopName: '',
  shopAddress: ''
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fillUser: (state, action: PayloadAction<UserType>) => {
      state.id = action.payload.id
      state.accountNumber = action.payload.accountNumber
      state.audience = action.payload.audience
      state.contactId = action.payload.contactId
      state.touchPoint = action.payload.touchPoint
      state.accoundEmail = action.payload.accoundEmail
      state.accoundLastName = action.payload.accoundLastName
      state.accoundFirstName = action.payload.accoundFirstName
      state.shopName = action.payload.shopName
      state.shopAddress = action.payload.shopAddress
    }
  }
})

export const { fillUser } = userSlice.actions

export default userSlice.reducer

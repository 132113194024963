import React, { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import './admin-panel-sidebar.css'

const AdminPanelSidebar = (): ReactElement => (
  <div className="sidebar-container">
    <div className="logo font__title--h3">ADMIN PANEL BCA</div>
    <Link className="sidebar-item font__body--bold-large" to="/admin/audiences">
      Gestion des Audiences
    </Link>
    <Link className="sidebar-item font__body--bold-large" to="/admin/products">
      Gestion des Produits
    </Link>
    <Link className="sidebar-item font__body--bold-large" to="/admin/packages">
      Gestion des Packages
    </Link>
    <Link className="sidebar-item font__body--bold-large" to="/admin/images">
      Gestion des Images
    </Link>
  </div>
)

export default AdminPanelSidebar

import React, { useState } from 'react'
import './package-table.css'
import axios from 'axios'
import { type PackageType, type ProductType } from '../../../utils/types'
import { ReactComponent as EditIcon } from '../../../assets/edit_icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/delete_icon.svg'
import { ReactComponent as TickIcon } from '../../../assets/tick.svg'
import { ReactComponent as DropdownIcon } from '../../../assets/dropdown_icon.svg'
import InfoModal from '../../utils/infoModal/InfoModal'

interface Column {
  header: string
  accessor: string
  render?: (row: any) => JSX.Element | string
}

interface PackageTableProps {
  columns: Column[]
  data: any[]
  expandedRowIndex: number | null
  setExpandedRowIndex: (index: number | null) => void
  onProductRemoved?: (packageId: number, productId: number) => void
  onProductAdded?: (packageId: number, newProduct: any) => void
  onProductUpdated?: (packageId: number, updatedProduct: any) => void
}

const apiBaseUrl = process.env.REACT_APP_API_URL

const PackageTable: React.FC<PackageTableProps> = ({
  columns,
  data,
  onProductRemoved,
  onProductAdded,
  onProductUpdated,
  expandedRowIndex,
  setExpandedRowIndex
}) => {
  const [newProduct, setNewProduct] = useState<Partial<ProductType> | null>(
    null
  )
  const [selectedPackage, setSelectedPackage] = useState<PackageType | null>(
    null
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalText, setModalText] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [editingProductId, setEditingProductId] = useState<number | null>(null)
  const [editingQuantity, setEditingQuantity] = useState<number | null>(null)
  const [addedProducts, setAddedProducts] = useState<number[]>([])

  const toggleRow = (index: number, pkg: PackageType): void => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index)
    setSelectedPackage(pkg)
  }

  const handleAddProductRow = async (): Promise<void> => {
    selectedPackage && await handleSaveNewProduct(selectedPackage.id)
    setNewProduct({ id: 0, brand: '', label: '', code: '', quantity: 1 })
  }

  const handleRemoveProduct = async (
    packageId: number,
    productId: number
  ): Promise<void> => {
    try {
      await axios.delete(
        `${apiBaseUrl}/packages/${packageId}/products/${productId}`
      )
      if (onProductRemoved) {
        setModalTitle('Supprimer un produit du Package')
        setModalText('Le produit a été supprimé avec succès')
        setIsModalOpen(true)
        onProductRemoved(packageId, productId)
      }
    } catch (error) {
      console.error('Failed to remove product from package:', error)
    }
  }

  const handleSaveNewProduct = async (packageId: number): Promise<void> => {
    if (!newProduct?.id) {
      return
    }
    if (selectedPackage) {
      const existingProduct = selectedPackage.products.some(
        (p) => p.code === newProduct.code
      )
      const existingProduct2 = addedProducts.some(
        (p) => p === newProduct.id
      )
      if (existingProduct || existingProduct2) {
        setModalTitle('Ajouter un produit au Package')
        setModalText('Ce produit est déjà existant dans ce package.')
        setIsModalOpen(true)
        return
      }
    }
    if (newProduct.quantity && newProduct.quantity < 1) {
      setModalTitle('Ajouter un produit au Package')
      setModalText(
        "La valeur indiquée n'est pas un entier positif ou la valeur indiquée n'est pas correcte. "
      )
      setIsModalOpen(true)
      return
    }
    if (!Number.isInteger(newProduct.quantity)) {
      setModalTitle('Ajouter un produit au Package')
      setModalText(
        "La valeur indiquée n'est pas un entier positif ou la valeur indiquée n'est pas correcte. "
      )
      setIsModalOpen(true)
      return
    }

    try {
      const response = await axios.post(
        `${apiBaseUrl}/packages/${packageId}/products`,
        newProduct
      )
      if (onProductAdded) {
        onProductAdded(packageId, response.data)
        setModalTitle('Ajouter un produit au Package')
        setModalText('Le produit a été ajouté avec succès')
        setIsModalOpen(true)
      }
      setAddedProducts([...addedProducts, newProduct.id])
      setNewProduct(null) // Clear the product row after saving
    } catch (error) {
      console.error('Failed to add product to package:', error)
    }
  }

  const handleUpdateProduct = async (
    packageId: number,
    productId: number
  ): Promise<void> => {
    if (editingQuantity === null) return
    if (editingQuantity < 1 || !Number.isInteger(editingQuantity)) {
      setModalTitle('Mettre à jour la quantité du produit')
      setModalText(
        "La valeur indiquée n'est pas un entier positif ou la valeur indiquée n'est pas correcte. "
      )
      setIsModalOpen(true)
      return
    }

    try {
      const response = await axios.put(
        `${apiBaseUrl}/packages/${packageId}/products/${productId}`,
        {
          quantity: editingQuantity
        }
      )
      if (onProductUpdated) {
        onProductUpdated(packageId, response.data)
        setModalTitle('Mettre à jour la quantité du produit')
        setModalText('La quantité du produit a été mise à jour avec succès')
        setIsModalOpen(true)
      }
      setEditingProductId(null)
      setEditingQuantity(null)
    } catch (error) {
      console.error('Failed to update product quantity:', error)
    }
  }

  const handleCancelNewProduct = (): void => {
    setNewProduct(null)
  }
  const getTotalQuantity = (products: any): number => {
    let total = 0
    products.forEach((p: any): void => {
      if (p.PackageProducts.quantity) {
        total += p.PackageProducts.quantity
      }
    })
    return total
  }

  const handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ): Promise<void> => {
    const value = e.target.value

    if (field === 'id') {
      try {
        const response = await axios.get(`${apiBaseUrl}/products/${value}`)
        const product = response.data
        setNewProduct({
          ...newProduct,
          id: product.id,
          brand: product.brand,
          label: product.label,
          code: product.code
        })
      } catch (error) {
        console.error('Failed to fetch product details:', error)
      }
    } else if (field === 'quantity') {
      setNewProduct({
        ...newProduct,
        [field]: Number(value)
      })
    }
  }

  return (
    <table className='generic-table-package'>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th className='font__body--bold-small' key={index}>
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className='package-table-admin'>
        {data.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            <tr
              className={
                expandedRowIndex === rowIndex
                  ? ' expanded-row-highlight'
                  : 'expanded-row'
              }
            >
              {columns.map((column, columnIndex) => (
                <td key={columnIndex}>
                  <div className='table-cell-package font__body--bold-small'>
                    {column.accessor === 'toggle' ? (
                      <button
                        className='package-toggle-btn'
                        onClick={() => {
                          toggleRow(rowIndex, row)
                        }}
                      >
                        <DropdownIcon
                          className={
                            expandedRowIndex === rowIndex ? 'rotate' : ''
                          }
                        />
                      </button>
                    ) : column.render ? (
                      column.render(row)
                    ) : row[column.accessor] || row[column.accessor] === 0 ? (
                      row[column.accessor]
                    ) : (
                      '--'
                    )}
                  </div>
                </td>
              ))}
            </tr>
            {expandedRowIndex === rowIndex && row.products && (
              <tr key={`expanded-${rowIndex}`}>
                <td colSpan={columns.length}>
                  <div className='expanded-row-content'>
                    <table className='product-table'>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>MARQUE</th>
                          <th>CODE PRODUIT</th>
                          <th>{`LIBELLE PRODUIT (${row.products.length} ${row.products.length > 1 ? 'Références' : 'Référence'})`}</th>
                          <th>{`QUANTITE (${'x' + getTotalQuantity(row.products)})`}</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody className='font__body--bold-small package-table-admin'>
                        {row.products.map((product: any, index: number) => (
                          <tr key={index}>
                            <td>{product.id}</td>
                            <td>{product.brand}</td>
                            <td>{product.code}</td>
                            <td>{product.label}</td>
                            <td>
                              {editingProductId === product.id ? (
                                <input
                                  type='number'
                                  value={editingQuantity || ''}
                                  onChange={(e) => {
                                    setEditingQuantity(Number(e.target.value))
                                  }}
                                />
                              ) : product.PackageProducts ? (
                                product.PackageProducts.quantity
                              ) : (
                                ''
                              )}
                            </td>
                            <td>
                              <div
                                className='actions-cell'
                                style={{ justifyContent: 'center' }}
                              >
                                {editingProductId === product.id ? (
                                  <>
                                    <TickIcon
                                      className='admin-icons'
                                      onClick={async () => {
                                        await handleUpdateProduct(
                                          row.id,
                                          product.id
                                        )
                                      }}
                                      style={{ fill: 'green' }}
                                    >
                                      Update
                                    </TickIcon>
                                    <button
                                      className='cancel-btn-package'
                                      onClick={() => {
                                        setEditingProductId(null)
                                        setEditingQuantity(null)
                                      }}
                                    >
                                      x
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <EditIcon
                                      className='admin-icons'
                                      onClick={() => {
                                        setEditingProductId(product.id)
                                        setEditingQuantity(
                                          product.PackageProducts?.quantity || 0
                                        )
                                      }}
                                      style={{ color: 'green' }}
                                    >
                                      Update
                                    </EditIcon>
                                    <DeleteIcon
                                      className='admin-icons'
                                      onClick={async () => {
                                        await handleRemoveProduct(
                                          row.id,
                                          product.id
                                        )
                                      }}
                                    >
                                      Delete
                                    </DeleteIcon>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                        {newProduct && (
                          <tr>
                            <td>
                              <input
                                type='text'
                                onChange={async (e) => {
                                  await handleInputChange(e, 'id')
                                }}
                                placeholder='ID'
                              />
                            </td>
                            <td>{newProduct.brand || '--'}</td>
                            <td>{newProduct.code || '--'}</td>
                            <td>{newProduct.label || '--'}</td>
                            <td>
                              <input
                                type='number'
                                value={newProduct.quantity || ''}
                                onChange={async (e) => {
                                  await handleInputChange(e, 'quantity')
                                }}
                                placeholder='Quantité'
                              />
                            </td>
                            <td>
                              <div
                                className='actions-cell'
                                style={{ justifyContent: 'center' }}
                              >
                                <TickIcon
                                  className='admin-icons'
                                  onClick={async () => {
                                    await handleSaveNewProduct(row.id)
                                  }}
                                  style={{ fill: 'green' }}
                                >
                                  Update
                                </TickIcon>
                                <button
                                  className='cancel-btn-package'
                                  onClick={handleCancelNewProduct}
                                >
                                  x
                                </button>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <button
                      className='add-product-button'
                      onClick={handleAddProductRow}
                    >
                      +
                    </button>
                  </div>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
      <InfoModal
        isOpen={isModalOpen}
        title={modalTitle}
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />
    </table>
  )
}

export default PackageTable

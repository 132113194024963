import React, { useEffect, useState } from 'react'
import './list-package.css'
import { type PackageType } from '../../../utils/types'

import { ReactComponent as EditIcon } from '../../../assets/edit_icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/delete_icon.svg'
import imageService from '../../../services/imageService'
import ToggleButton from '../../utils/toggleButton/ToggleButton'
import PackageTable from '../packageTable/PackageTable'
import Modal from '../../utils/modal/Modal'
import EditPackage from '../editPackage/EditPackage'
import ConfirmationModal from '../../utils/confirmationModal/ConfirmationModal'
import packageService from '../../../services/packageService'
import InfoModal from '../../utils/infoModal/InfoModal'
import AddImagePackage from '../addImagePackage/AddImagePackage'

interface ListPackageProps {
  packagesList: PackageType[]
}

const ListPackage: React.FC<ListPackageProps> = ({ packagesList }) => {
  const [packages, setPackages] = useState<PackageType[]>(packagesList)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [modalText, setmodalText] = useState('')
  const [infoModalTitle, setInfoModalTitle] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [currentPackage, setCurrentPackage] = useState<PackageType>()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null)
  const [selectedPackage, setSelectedPackage] = useState<Partial<PackageType>>({
    code: '',
    label: '',
    minToOrder: null,
    maxToOrder: null,
    minToWinPoints: null,
    maxToWinPoints: null,
    pointValue: null
  })
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false)

  const handleProductRemoved = (packageId: number, productId: number): void => {
    setPackages((prevPackages) =>
      prevPackages.map((pkg) =>
        pkg.id === packageId
          ? {
              ...pkg,
              products: pkg.products.filter(
                (product) => product.id !== productId
              )
            }
          : pkg
      )
    )
  }

  const handleProductAdded = (packageId: number, newProduct: any): void => {
    setPackages((prevPackages) =>
      prevPackages.map((pkg) =>
        pkg.id === packageId
          ? {
              ...pkg,
              products: [...pkg.products, newProduct.Product]
            }
          : pkg
      )
    )
  }

  const handleProductUpdated = (
    packageId: number,
    updatedProduct: any
  ): void => {
    setPackages((prevPackages) =>
      prevPackages.map((pkg) =>
        pkg.id === packageId
          ? {
              ...pkg,
              products: pkg.products.map((product) =>
                product.id === updatedProduct.productId
                  ? {
                      ...product,
                      PackageProducts: { quantity: updatedProduct.quantity }
                    }
                  : product
              )
            }
          : pkg
      )
    )
  }

  const updatePackage = (newPackage: PackageType): void => {
    setExpandedRowIndex(null)
    setSelectedPackage(newPackage)
    setIsEditModalOpen(true)
  }

  const handleEditPackage = (updatedPackage: PackageType): void => {
    setPackages(
      packages.map((newPackage) =>
        newPackage.id === updatedPackage.id ? updatedPackage : newPackage
      )
    )
  }

  const deletePackageAction = async (id: number): Promise<void> => {
    try {
      await packageService.deletePackage(id).then(() => {
        setInfoModalTitle('Supprimer le package')
        setmodalText('Le package a été supprimé avec succès')
        setIsModalOpen(true)
        setPackages(packages.filter((a) => a.id !== id))
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenImageModal = async (pa: PackageType): Promise<void> => {
    try {
      setCurrentPackage(pa)
      setIsImageModalOpen(true)
      await fetchImages()
    } catch (error) {
      console.error('Failed to delete image:', error)
    }
  }

  const fetchImages = async (): Promise<void> => {
    const urls = await imageService.listImages()
    const notFoundIndex = urls.findIndex((url) =>
      url.includes('image_placeholder')
    )
    if (notFoundIndex > 0) {
      const [notFoundUrl] = urls.splice(notFoundIndex, 1)
      urls.unshift(notFoundUrl)
    }
    setImageUrls(urls)
  }

  const handleToggle = (pkg: PackageType): void => {
    setSelectedPackage(pkg)
    let packageStatus: boolean = true
    pkg.products?.map((pkg) => {
      if (!pkg.enabled) {
        packageStatus = false
      }
      return packageStatus
    })
    if (packageStatus) {
      setIsConfirmationModalOpen(true)
    } else {
      setInfoModalTitle('Désactiver le package')
      setmodalText(
        "Le package ne peut pas être activé, car l'un des produits est désactivé."
      )
      setIsModalOpen(true)
    }
  }

  const cancelToggle = (): void => {
    setIsConfirmationModalOpen(false)
  }

  const confirmToggle = async (): Promise<void> => {
    if (selectedPackage?.id) {
      const newEnabledStatus = !selectedPackage.enabled
      await packageService.disablePackage(selectedPackage.id, newEnabledStatus)
      const updatedPackages = packages.map((p) =>
        p.id === selectedPackage.id ? { ...p, enabled: newEnabledStatus } : p
      )
      setPackages(updatedPackages)
    }
    setIsConfirmationModalOpen(false)
  }

  const columns = [
    { header: '', accessor: 'toggle' },
    { header: 'ID', accessor: 'id' },
    {
      header: 'AUDIENCE',
      accessor: 'audience',
      render: (row: PackageType) => <>{row.Audience?.name || '--'}</>
    },
    { header: 'NOM PACKAGE', accessor: 'label' },
    { header: 'MINIMUM PACKAGE', accessor: 'minToOrder' },
    { header: 'MAXIMUM PACKAGE', accessor: 'maxToOrder' },
    { header: 'POINT QUANTITÉ MINIMUM', accessor: 'minToWinPoints' },
    { header: 'POINT QUANTITÉ MAXIMUM', accessor: 'maxToWinPoints' },
    { header: 'NOMBRE DE POINTS', accessor: 'pointValue' },
    {
      header: 'IMAGES',
      accessor: 'images',
      render: (row: PackageType) => {
        return (
          <>
            <img
              src={imageService.getImageUrl(row.imageUrl || '')}
              alt='Package'
              style={{ width: '50px', height: '50px', cursor: 'pointer' }}
              onClick={async () => {
                await handleOpenImageModal(row)
              }}
            />
          </>
        )
      }
    },
    {
      header: 'ACTIONS',
      accessor: 'actions',
      render: (row: PackageType) => (
        <div className='actions-cell'>
          <EditIcon
            className='admin-icons'
            onClick={() => {
              updatePackage(row)
            }}
            style={{ color: 'green' }}
          >
            Update
          </EditIcon>
          <DeleteIcon
            className='admin-icons'
            onClick={() => {
              setSelectedPackage(row)
              setIsDeleteConfirmationModalOpen(true)
            }}
          >
            Delete
          </DeleteIcon>
          <ToggleButton
            enabled={row.enabled}
            onClick={() => {
              handleToggle(row)
            }}
          />
        </div>
      )
    }
  ]

  useEffect(() => {
    setPackages(packagesList)
  }, [packagesList])

  return (
    <div className='packages-container-list'>
      <InfoModal
        isOpen={isModalOpen}
        title={infoModalTitle}
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />
      <PackageTable
        columns={columns}
        data={packages}
        expandedRowIndex={expandedRowIndex}
        setExpandedRowIndex={setExpandedRowIndex}
        onProductRemoved={handleProductRemoved}
        onProductAdded={handleProductAdded}
        onProductUpdated={handleProductUpdated}
      />
      <Modal
        isOpen={isEditModalOpen}
        title='Modifier un package'
        onClose={() => {
          setIsEditModalOpen(false)
        }}
      >
        <EditPackage
          packageProp={selectedPackage}
          closeModal={() => {
            setIsEditModalOpen(false)
          }}
          onEditPackage={handleEditPackage}
        />
      </Modal>

      <Modal
        isOpen={isImageModalOpen}
        title='Associer une nouvelle image au produit'
        onClose={() => {
          setIsImageModalOpen(false)
        }}
      >
        <AddImagePackage
          closeModal={() => {
            setIsImageModalOpen(false)
          }}
          imageUrls={imageUrls}
          currentPackage={currentPackage}
          setPackages={setPackages}
        />
      </Modal>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title="Indication d'un package en rupture"
        message={
          selectedPackage.enabled
            ? 'Attention, le package ne sera plus visible par le point de vente.'
            : 'Le package sera de nouveau visible pour le point de vente.'
        }
        subMessage={'Confirmez-vous ?'}
        onClose={cancelToggle}
        onConfirme={confirmToggle}
      ></ConfirmationModal>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        title='Supprimer un package'
        message='Voulez-vous supprimer ce package ?'
        onClose={() => {
          setIsDeleteConfirmationModalOpen(false)
        }}
        onConfirme={async () => {
          if (selectedPackage.id) {
            await deletePackageAction(selectedPackage.id)
            setIsDeleteConfirmationModalOpen(false)
          }
        }}
      ></ConfirmationModal>
    </div>
  )
}

export default ListPackage

import React, { useEffect, useState } from 'react'
import './list-product.css'
import { type ProductType } from '../../../utils/types'
import AdminTable from '../../utils/adminTable/AdminTable'
import InfoModal from '../../utils/infoModal/InfoModal'
import Modal from '../../utils/modal/Modal'
import { ReactComponent as EditIcon } from '../../../assets/edit_icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/delete_icon.svg'
import productService from '../../../services/productService'
import EditProduct from '../editProduct/EditProduct'
import imageService from '../../../services/imageService'
import AddImage from '../addImage/AddImage'
import ToggleButton from '../../utils/toggleButton/ToggleButton'
import ConfirmationModal from '../../utils/confirmationModal/ConfirmationModal'

interface ListProductProps {
  productsList: ProductType[]
}

const ListProduct: React.FC<ListProductProps> = ({ productsList }) => {
  const [products, setProducts] = useState<ProductType[]>(productsList)
  const [selectedProduct, setSelectedProduct] = useState<Partial<ProductType>>({
    brand: '',
    code: '',
    label: '',
    minToOrder: null,
    maxToOrder: null,
    minToWinPoints: null,
    maxToWinPoints: null,
    pointValue: null
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [modalText, setmodalText] = useState('')
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [currentProduct, setCurrentProduct] = useState<ProductType>()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false)

  const columns = [
    { header: 'ID', accessor: 'id' },
    {
      header: 'AUDIENCE',
      accessor: 'audience',
      render: (row: ProductType) => <>{row.Audience?.name || '--'}</>
    },
    { header: 'MARQUE', accessor: 'brand' },
    { header: 'CODE PRODUIT', accessor: 'code' },
    { header: 'LIBELLE PRODUIT', accessor: 'label' },
    { header: 'MINIMUM PRODUIT', accessor: 'minToOrder' },
    { header: 'MAXIMUM PRODUIT', accessor: 'maxToOrder' },
    { header: 'POINT QUANTITÉ MINIMUM', accessor: 'minToWinPoints' },
    { header: 'POINT QUANTITÉ MAXIMUM', accessor: 'maxToWinPoints' },
    { header: 'NOMBRE DE POINTS', accessor: 'pointValue' },
    {
      header: 'IMAGES',
      accessor: 'images',
      render: (row: ProductType) => {
        return (
          <>
            <img
              src={imageService.getImageUrl(row.imageUrl || '')}
              alt='Product'
              style={{ width: '50px', height: '50px', cursor: 'pointer' }}
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              onClick={async () => {
                await handleOpenImageModal(row)
              }}
            />
          </>
        )
      }
    },
    {
      header: 'ACTIONS',
      accessor: 'actions',
      render: (row: ProductType) => (
        <div className='actions-cell'>
          <EditIcon
            className='admin-icons'
            onClick={() => {
              updateProduct(row)
            }}
            style={{ color: 'green' }}
          >
            Update
          </EditIcon>
          <DeleteIcon
            className='admin-icons'
            onClick={() => {
              setSelectedProduct(row)
              setIsDeleteConfirmationModalOpen(true)
            }}
          >
            Delete
          </DeleteIcon>
          <ToggleButton
            enabled={row.enabled}
            onClick={() => {
              handleToggle(row)
            }}
          />
        </div>
      )
    }
  ]

  const handleToggle = (product: ProductType): void => {
    setSelectedProduct(product)
    setIsConfirmationModalOpen(true)
  }

  const confirmToggle = async (): Promise<void> => {
    if (selectedProduct?.id) {
      const newEnabledStatus = !selectedProduct.enabled
      await productService.disableProduct(selectedProduct.id, newEnabledStatus)
      const updatedProducts = products.map((p) =>
        p.id === selectedProduct.id ? { ...p, enabled: newEnabledStatus } : p
      )
      setProducts(updatedProducts)
    }
    setIsConfirmationModalOpen(false)
  }

  const cancelToggle = (): void => {
    setIsConfirmationModalOpen(false)
  }

  const deleteProductAction = async (id: number): Promise<void> => {
    try {
      await productService.deleteProduct(id).then(() => {
        setmodalText('Le produit a été supprimé avec succès')
        setIsModalOpen(true)
        setProducts(products.filter((a) => a.id !== id))
      })
    } catch (error) {
      console.log(error)
    }
  }

  const updateProduct = (product: ProductType): void => {
    setSelectedProduct(product)
    setIsEditModalOpen(true)
  }

  const handleEditProduct = (updatedProduct: ProductType): void => {
    setProducts(
      products.map((product) =>
        product.id === updatedProduct.id ? updatedProduct : product
      )
    )
  }

  useEffect(() => {
    setProducts(productsList)
  }, [productsList])

  const fetchImages = async (): Promise<void> => {
    const urls = await imageService.listImages()
    const notFoundIndex = urls.findIndex((url) =>
      url.includes('image_placeholder')
    )
    if (notFoundIndex > 0) {
      const [notFoundUrl] = urls.splice(notFoundIndex, 1)
      urls.unshift(notFoundUrl)
    }
    setImageUrls(urls)
  }

  const handleOpenImageModal = async (product: ProductType): Promise<void> => {
    try {
      setCurrentProduct(product)
      setIsImageModalOpen(true)
      await fetchImages()
    } catch (error) {
      console.error('Failed to delete image:', error)
    }
  }

  return (
    <div className='products-container-list'>
      <AdminTable columns={columns} data={products} />

      <InfoModal
        isOpen={isModalOpen}
        title='Supprimer le produit'
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />
      <Modal
        isOpen={isEditModalOpen}
        title='Modifier un produit'
        onClose={() => {
          setIsEditModalOpen(false)
        }}
      >
        <EditProduct
          productProp={selectedProduct}
          closeModal={() => {
            setIsEditModalOpen(false)
          }}
          onEditProduct={handleEditProduct}
        />
      </Modal>
      <Modal
        isOpen={isImageModalOpen}
        title='Associer une nouvelle image au produit'
        onClose={() => {
          setIsImageModalOpen(false)
        }}
      >
        <AddImage
          closeModal={() => {
            setIsImageModalOpen(false)
          }}
          imageUrls={imageUrls}
          currentProduct={currentProduct}
          setProducts={setProducts}
        />
      </Modal>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title="Indication d'un produit en rupture"
        message={
          selectedProduct.enabled
            ? 'Attention, le produit ne sera plus visible par le point de vente.'
            : 'Le produit sera de nouveau visible pour le point de vente.'
        }
        subMessage={'Confirmez-vous ?'}
        onClose={cancelToggle}
        onConfirme={confirmToggle}
      ></ConfirmationModal>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        title='Supprimer un produit'
        message='Voulez-vous supprimer ce produit ?'
        onClose={() => {
          setIsDeleteConfirmationModalOpen(false)
        }}
        onConfirme={async () => {
          if (selectedProduct.id) {
            await deleteProductAction(selectedProduct.id)
            setIsDeleteConfirmationModalOpen(false)
          }
        }}
      ></ConfirmationModal>
    </div>
  )
}

export default ListProduct

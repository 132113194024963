import React, { useState } from 'react'
import './add-image.css'
import { type ProductType } from '../../../utils/types'
import productService from '../../../services/productService'
import imageService from '../../../services/imageService'
import InfoModal from '../../utils/infoModal/InfoModal'
import AdminButton from '../../utils/adminButton/AdminButton'
import { extractImageNameFromUrl } from '../../../utils/utils'

interface AddImageProps {
  closeModal: () => void
  setProducts: (productList: ProductType[]) => void
  currentProduct?: ProductType
  imageUrls: string[]
}

const AddImage: React.FC<AddImageProps> = ({
  closeModal,
  currentProduct,
  imageUrls,
  setProducts
}) => {
  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    currentProduct?.imageUrl
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalText, setmodalText] = useState('')
  const handleSelectImage = async (imageUrl: string): Promise<void> => {
    setSelectedImage(imageUrl)
  }
  const updateImage = async (): Promise<void> => {
    if (currentProduct && selectedImage) {
      await imageService
        .updateProductImage(
          currentProduct.id,
          selectedImage
        )
        .then(() => {
          setmodalText('Image mise à jour avec succès')
          setIsModalOpen(true)
        })
      const fetchProducts = async (): Promise<void> => {
        const data = await productService.getAllProducts()
        setProducts(data)
      }
      await fetchProducts()
    }
  }

  return (
    <div className='add-image-container'>
      <div className='current-image-container'>
        <div className='current-image-text'>Image actuelle</div>
        <div className='image-container'>
          <img
            src={imageService.getImageUrl(selectedImage || '')}
            alt='Product'
            className='actual-image-item'
          />
        </div>
      </div>
      <div className='divider'></div>
      <div className='biblio-text'>Bibliothèque d&apos;images</div>
      <div className='image-selection-grid'>
        {imageUrls.map((image) => (
          <div key={image} className='add-image-selection'>
          <img
            src={imageService.getImageUrl(image)}
            alt='Product'
            onClick={async () => {
              await handleSelectImage(image)
            }}
            className={`image-item ${selectedImage === image && 'selected-image-item'}`}
          />
        <p className='font__body--bold-small'>{extractImageNameFromUrl(image)}</p>
        </div>
        ))}
      </div>
      <AdminButton className='update-image-button' onClick={updateImage}>
        Terminer
      </AdminButton>
      <InfoModal
        isOpen={isModalOpen}
        title='Mise à jours Image'
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
          closeModal()
        }}
      />
    </div>
  )
}

export default AddImage

import React, { useRef, useEffect, useState } from 'react'
import './catalog-package-card.css'
import MainButton from '../../utils/mainButton/MainButton'
import { type PackageType } from '../../../utils/types'
import { ReactComponent as DropdownIcon } from '../../../assets/dropdown_icon.svg'
import { addToPackageCart } from '../../../slices/cartSlice'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { getPointsSvg } from '../../../services/pointsService'
import imageService from '../../../services/imageService'
import HandlePackageCartButton from '../../utils/handlePackageCartButton/HandlePackageCartButton'
import GoldenTickerImg from '../../../assets/golden-ticket.png'
import MacaronPoints from '../../utils/macaronPoints/macaronPoints'

interface CatalogPackageCardProps {
  pkg: PackageType
  isExpanded: boolean // Add prop for expansion
  onExpand: () => void
}

const CatalogPackageCard: React.FC<CatalogPackageCardProps> = ({
  pkg,
  onExpand,
  isExpanded
}) => {
  const dispatch = useAppDispatch()
  const contentRef = useRef<HTMLDivElement>(null)
  const packagesList = useAppSelector((state) => state.cart.packages)
  const [firstWorkingDay, setFirstWorkingDay] = useState('')

  useEffect(() => {
    const getFirstWorkingDayOfNextMonth = (): string => {
      const today = new Date()
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1)

      const firstWorkingDay = nextMonth

      while (firstWorkingDay.getDay() === 0 || firstWorkingDay.getDay() === 6) {
        firstWorkingDay.setDate(firstWorkingDay.getDate() + 1)
      }

      const day = firstWorkingDay.getDate().toString().padStart(2, '0')
      const month = (firstWorkingDay.getMonth() + 1).toString().padStart(2, '0')
      return `${day}/${month}`
    }

    setFirstWorkingDay(getFirstWorkingDayOfNextMonth())
  }, [])

  const generateQuantityText = (pkg: PackageType): JSX.Element | null => {
    if (pkg.maxToOrder && pkg.minToOrder) {
      return (
        <p className='font__body--medium package-description'>
          Pour valider votre panier, la quantité doit être comprise entre{' '}
          {pkg.minToOrder} et {pkg.maxToOrder}.
        </p>
      )
    } else if (pkg.minToOrder && !pkg.maxToOrder) {
      return (
        <p className='font__body--medium package-description'>
          Pour valider le panier, la quantité minimale à sélectionner est de{' '}
          {pkg.minToOrder}.
        </p>
      )
    } else if (!pkg.minToOrder && pkg.maxToOrder) {
      return (
        <p className='font__body--medium package-description'>
          Pour valider le panier, la quantité maximale à sélectionner est de{' '}
          {pkg.maxToOrder}.
        </p>
      )
    } else {
      return null
    }
  }
  const generatePointsText = (pkg: PackageType): JSX.Element | null => {
    if (pkg.maxToWinPoints && pkg.minToWinPoints && pkg.pointValue !== 0) {
      return (
        <div className='points-text-container'>
          {pkg.pointValue && <MacaronPoints points={pkg.pointValue} />}
          <div
            className='font__body--bold-small'
            style={{ color: 'var(--error-color)' }}
          >
            Entre {pkg.minToWinPoints} et {pkg.maxToWinPoints} quantités, vous
            pouvez gagner {pkg.pointValue} points par quantité commandée.
          </div>
        </div>
      )
    } else if (!pkg.maxToWinPoints && pkg.minToWinPoints) {
      return (
        <div className='points-text-container'>
          {pkg.pointValue && <div>{getPointsSvg(pkg.pointValue)}</div>}
          <div
            className='font__body--bold-small'
            style={{ color: 'var(--error-color)' }}
          >
            Plus de {pkg.minToWinPoints} quantités, vous pouvez gagner{' '}
            {pkg.pointValue} points par quantité commandée.
          </div>
        </div>
      )
    } else if (pkg.maxToWinPoints && !pkg.minToWinPoints) {
      return (
        <div className='points-text-container'>
          {pkg.pointValue && <div>{getPointsSvg(pkg.pointValue)}</div>}
          <div
            className='font__body--bold-small'
            style={{ color: 'var(--error-color)' }}
          >
            Moins de {pkg.maxToWinPoints} quantités, vous pouvez gagner{' '}
            {pkg.pointValue} par quantité commandée.
          </div>
        </div>
      )
    } else if (pkg.pointValue === 0) {
      return (
        <div className='points-text-container'>
          <img src={GoldenTickerImg} width={75} height={30} />
          <div className='font__body--bold-small' style={{ color: '#B8860B' }}>
            Débloquez un accès au Ticket d&apos;or quotidien à partir du{' '}
            {firstWorkingDay}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const getPackageQuantity: () => any = () => {
    const foundPackage = packagesList.find(
      (item: PackageType) => item.id === pkg.id
    )
    return foundPackage ? foundPackage.quantity : 0
  }

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isExpanded ? '350px' : '0px'
    }
  }, [isExpanded])

  return (
    <div
      className={`package-card-container ${isExpanded ? 'highlighted' : ''}`}
    >
      <img
        src={imageService.getImageUrl(pkg.imageUrl || '')}
        alt='Package'
        className='package-catalog-img'
      />
      <div className='font__body--bold-medium package-label'>{pkg.label}</div>
      <div className='package-details'>
        <div className='details-dropdown'>
          <div className='details-button' onClick={onExpand}>
            <button className='expand-button'>Détails</button>
            <DropdownIcon className={isExpanded ? 'rotate' : ''} />
          </div>
          <div ref={contentRef} className='content'>
            <div>
              <b className='font__body--bold-medium'>
                {`Contenu du pack (${pkg.products.length} Références)`}:
              </b>

              <ul className='package-products-list'>
                {pkg.products.map((p: any) => (
                  <li
                    key={p.id}
                    className='package-products-item font__body-xsmall'
                  >
                    <span>{`${p.PackageProducts.quantity}x`}</span>
                    <span>{p.label}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className='font__body--semi-bold'>
              {generateQuantityText(pkg)}
            </div>
            {generatePointsText(pkg)}
          </div>
        </div>
      </div>
      {getPackageQuantity() === 0 ? (
        <MainButton
          className='add-to-card-button'
          onClick={() => {
            dispatch(addToPackageCart(pkg))
          }}
        >
          Ajouter au panier
        </MainButton>
      ) : (
        <HandlePackageCartButton
          pkg={pkg}
          getPackageQuantity={getPackageQuantity}
        />
      )}
    </div>
  )
}

export default CatalogPackageCard

import React, { type ReactElement } from 'react'
import './order-confirmation.css'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../utils/hooks'
import { getTotalPointInCart } from '../../services/cartService'
import OrderConfirmationImg from '../../assets/order-confirmation.png'
import { ReactComponent as OrderHistoryIcon } from '../../assets/order-history.svg'
import { ReactComponent as PadlockIcon } from '../../assets/padlock.svg'
import MacaronPoints from '../../components/utils/macaronPoints/macaronPoints'

const OrderConfirmation = (): ReactElement => {
  const productOrderedList = useAppSelector((state) => state.cart.products)
  const packageOrderedList = useAppSelector((state) => state.cart.packages)
  const totalOrderPoint = getTotalPointInCart(
    productOrderedList,
    packageOrderedList
  )

  return (
    <div className='order-confirmation-page'>
      <Link to='/order-list' className='order-history-link mobile'>
        <OrderHistoryIcon />
        <div className='font__body--bold-medium'>Historique de commande</div>
      </Link>
      <div className='order-confirmation-container'>
        <Link to='/order-list' className='order-history-link desktop'>
          <OrderHistoryIcon />
          <div className='order-history-link-label font__body--bold-medium'>
            Historique de commande
          </div>
        </Link>
        <div className='order-confirmation-img'>
          <img
            src={OrderConfirmationImg}
            alt='Image de confirmation de commande'
          />
        </div>
        <div className='order-confirmation-mention-container'>
          <div className='order-confirmation-mention font__body--bold-large'>
            Merci pour votre commande !
          </div>
          {totalOrderPoint > 0 && !null ? (
            <>
              <div className='point-mention font__body--bold-large'>
                <MacaronPoints points={totalOrderPoint} className='history-macaron' />
                vous ont été crédités !
              </div>
              <div className='golden-ticket-mention font__body--bold-large'>
                <PadlockIcon />
                <div className='golden-ticket-mention-text'>
                  Dès le 1er jour ouvré du mois prochain, vous aurez accès au
                  Ticket d&apos;or quotidien jusqu&apos;au dernier jour du mois
                  concerné.
                </div>
              </div>
            </>
          ) : (
            <div className='no-golden-ticket-mention font__body--bold-large'>
              N&apos;hésitez pas à vous reconnecter prochainement pour découvrir
              les prochaines offres du mois afin de cumuler des points et
              débloquer un accès quotidien au Ticket d&apos;or.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrderConfirmation

import axios from 'axios'
import { type PackageType } from '../utils/types'

const apiBaseUrl = process.env.REACT_APP_API_URL

const createPackage = async (newPackage: any): Promise<PackageType> => {
  try {
    const response = await axios.post(`${apiBaseUrl}/packages`, newPackage)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const getAllPackages = async (): Promise<PackageType[]> => {
  try {
    const response = await axios.get<PackageType[]>(`${apiBaseUrl}/packages`)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const updatePackage = async (id: number, newObject: any): Promise<void> => {
  try {
    const response = await axios.put(`${apiBaseUrl}/packages/${id}`, newObject)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const deletePackage = async (id: number): Promise<void> => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/packages/${id}`)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const importPackages = async (file: FormData): Promise<void> => {
  try {
    const response = await axios.post(`${apiBaseUrl}/packages/import`, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    console.log('File uploaded successfully', response.data)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data) {
      const errorMessages = error.response.data.errors
      if (Array.isArray(errorMessages)) {
        const errorMessageString = errorMessages.join('\n')
        throw new Error(errorMessageString)
      } else {
        throw new Error(error.response.data.message as string)
      }
    } else {
      throw new Error(
        "Une erreur est survenue lors de l'importation des packages."
      )
    }
  }
}

const downloadExportedPackages = async (): Promise<void> => {
  try {
    const response = await axios.get(`${apiBaseUrl}/packages/export`, {
      responseType: 'blob'
    })

    const file = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })

    const fileURL = URL.createObjectURL(file)

    const link = document.createElement('a')
    link.href = fileURL
    link.setAttribute('download', 'fichier_packages.xlsx')
    document.body.appendChild(link)
    link.click()

    if (link.parentNode) {
      link.parentNode.removeChild(link)
    }
    URL.revokeObjectURL(fileURL)
  } catch (error) {
    console.error('Failed to download the file:', error)
  }
}

const disablePackage = async (
  id: number,
  enabled: boolean
): Promise<Partial<PackageType>> => {
  try {
    return await axios.put(`${apiBaseUrl}/packages/disable/${id}`, { enabled })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `Une erreur est survenue lors de la désactivation du pacakge ${id}:`,
      error
    )
    return {}
  }
}

const getPackagesByAudience = async (id: number): Promise<PackageType[]> => {
  try {
    const response = await axios.get(
      `${apiBaseUrl}/packages/package-by-audience/${id}`
    )
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

export default {
  createPackage,
  getAllPackages,
  updatePackage,
  deletePackage,
  importPackages,
  downloadExportedPackages,
  disablePackage,
  getPackagesByAudience
}

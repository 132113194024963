import React, { useState, type ReactElement, useLayoutEffect, useEffect } from 'react'
import './order-list.css'
import { ReactComponent as CartWhiteIcon } from '../../assets/cart_white.svg'
import { ReactComponent as SearchIcon } from '../../assets/loop_icon.svg'
import { ReactComponent as HistoryIcon } from '../../assets/order-history.svg'
import { ReactComponent as ResetFilterIcon } from '../../assets/reset.svg'
import { ReactComponent as DateFilterIcon } from '../../assets/filter.svg'
import { ReactComponent as Carret } from '../../assets/dropdown_icon.svg'
import MainLink from '../../components/utils/mainLink/MainLink'
import OrderCard from '../../components/orderCard/OrderCard'
import { type OrderType } from '../../utils/types'
import orderService from '../../services/orderService'
import { useAppSelector } from '../../utils/hooks'
import { type OrderFrequencyResponse } from '../productCatalog/ProductCatalog'

const OrderList = (): ReactElement => {
  const user = useAppSelector((state) => state.user)

  const [orders, setOrders] = useState<OrderType[]>([])
  const [filteredOrders, setFilteredOrders] = useState<OrderType[]>([])
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [isFiltersActive, setIsFiltersActive] = useState<boolean>(false)
  const isOrderAvailable = isFiltersActive && filteredOrders.length === 0 ? <span className='order-error-message'>Aucune commande n&apos;a été passée aux dates indiquées.</span> : orders?.map((order) => (<OrderCard key={order.id} order={order} />))
  const [isFiltersOpened, setIsFiltersOpened] = useState<boolean>(false)
  const [hasAccess, setHasAccess] = useState<boolean>(false)

  useEffect(() => {
    const accountNumber = user?.accountNumber
    if (accountNumber) {
      orderService.getOrderByAccountNumber(user.accountNumber)
        .then(res => {
          const reorderedOrders = res.slice().reverse()
          setOrders(reorderedOrders)
        })
        .catch(e => {
          console.error(e)
        })
    }
  }, [user])

  useLayoutEffect(() => {
    const data = {
      accountNumber: user.accountNumber,
      audience: user.audience
    }
    const checkOrderFrequencyLimit = async (): Promise<void> => {
      try {
        const res: OrderFrequencyResponse = await orderService.checkOrderFrequency(data)
        setHasAccess(res.catalogAccess as boolean)
      } catch (err) {
        console.error('Error checking order frequency:', err)
      }
    }
    void checkOrderFrequencyLimit()
  }, [user])

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setStartDate(e.target.value)
  }
  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEndDate(e.target.value)
  }
  const filterOrders = (): void => {
    const filtered = orders?.filter((order) => {
      const orderDate = new Date(String(order.createdAt))
      const start = startDate ? new Date(startDate) : new Date(0)
      const end = endDate ? new Date(endDate) : new Date()
      end.setHours(23, 59, 59, 999)
      return orderDate >= start && orderDate <= end
    })
    setIsFiltersActive(true)
    filtered && setFilteredOrders(filtered)
  }
  const handleResetDateFilter = (): void => {
    setFilteredOrders([])
    setStartDate('')
    setEndDate('')
    setIsFiltersActive(false)
  }
  const toggleFilters: () => void = () => {
    setIsFiltersOpened(!isFiltersOpened)
  }

  return (
    <div className='order-list-page'>
      <div className="order-list-page-header">
        <div className='order-history-link order-list-history-link'>
          <HistoryIcon />
          <div className='order-history-link-label font__body--bold-medium'>Historique de commandes</div>
        </div>
        {!isFiltersOpened
          ? <button className='date-filter-button' onClick={toggleFilters}>
              <DateFilterIcon />
              <span>Filtres</span>
            </button>
          : <div className='order-list-date-filter'>
            <div className='order-list-element'>
              <label>Début</label>
              <input type="date" className="input-date" value={startDate} onChange={handleStartDateChange} />
            </div>
            <div className='order-list-element'>
              <label>Fin</label>
              <input type="date" className="input-date" value={endDate} onChange={handleEndDateChange} />
            </div>
            <div className='order-list-element'>
              <div className='order-list-filter-search' onClick={filterOrders}>
                <SearchIcon title='Rechercher'/>
              </div>
              <div className='order-list-filter-search' onClick={() => { handleResetDateFilter() }}>
                <ResetFilterIcon title='Réinitialiser les filtres'/>
              </div>
              <Carret className='hide-filters-responsive' onClick={toggleFilters} title='Masquer les filtres' />
            </div>
            <button className='hide-filters' onClick={toggleFilters}>Masquer les filtres</button>
          </div>
        }
        {hasAccess &&
        <MainLink
          url='/product-catalog'
        >
          <CartWhiteIcon />
           <div className='link-label font__body--medium'>Revenir à ma commande</div>
        </MainLink>}
      </div>
      <div className="order-list-container">
        {filteredOrders.length > 0
          ? (
              filteredOrders.map((order) => (
              <OrderCard key={order.id} order={order} />
              ))
            )
          : isOrderAvailable
        }
      </div>
    </div>
  )
}

export default OrderList

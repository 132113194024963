import React, { useState, type ReactElement, useEffect } from 'react'
import { ReactComponent as ImportIcon } from '../../assets/import_icon.svg'
import './products.css'
import AdminButton from '../utils/adminButton/AdminButton'
import Modal from '../utils/modal/Modal'
import AddProducts from './addProduct/AddProduct'
import { type ProductType } from '../../utils/types'
import ListProduct from './listProduct/ListProduct'
import productService from '../../services/productService'
import InfoModal from '../utils/infoModal/InfoModal'

const Products = (): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)
  const [products, setProducts] = useState<ProductType[]>([])
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [modalText, setmodalText] = useState<any>()
  const [shouldCloseModal, setShouldCloseModal] = useState(false)

  const onDrop = (e: React.DragEvent<HTMLLabelElement>): void => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length > 0) {
      setSelectedFile(files[0])
    }
  }

  const onDragOver = (e: React.DragEvent<HTMLLabelElement>): void => {
    e.preventDefault()
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0])
    }
  }

  const handleUpload = async (): Promise<void> => {
    if (selectedFile) {
      const formData = new FormData()
      formData.append('file', selectedFile)

      try {
        await productService.importProducts(formData)
        setIsInfoModalOpen(true)
        setmodalText('Produits importés avec succès')
        setSelectedFile(null)
        await handleAddProduct()
        setShouldCloseModal(true)
      } catch (error: any) {
        setIsInfoModalOpen(true)
        setShouldCloseModal(false)
        let errorMessage =
          "Une erreur est survenue lors de l'importation des produits."

        if (error?.isAxiosError) {
          const errorMessages = error.response?.data?.errors
          if (Array.isArray(errorMessages)) {
            errorMessage = errorMessages.join('\n')
          } else {
            errorMessage = error.response?.data?.message || errorMessage
          }
        } else {
          errorMessage = error.message || errorMessage
        }
        const errorMessagesArray = errorMessage.split('\n')

        const formattedErrorMessages = errorMessagesArray.map((msg, index) => (
          <div style={{ padding: '5px' }} key={index}>
            {msg}
          </div>
        ))

        setmodalText(formattedErrorMessages)
      }
    }
  }
  const handleExport = async (): Promise<void> => {
    try {
      await productService.downloadExportedProducts()
    } catch (error: any) {
      console.error('Failed to export data ', error)
    }
  }

  const handleAddProduct = async (): Promise<void> => {
    try {
      const response = await productService.getAllProducts()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setProducts(response)
    } catch (error) {
      console.error('Failed to fetch audiences:', error)
    }
  }

  useEffect(() => {
    const fetchProducts = async (): Promise<void> => {
      try {
        const response = await productService.getAllProducts()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setProducts(response)
      } catch (error) {
        console.error('Failed to fetch audiences:', error)
      }
    }
    void fetchProducts()
  }, [])

  return (
    <div className='products-container'>
      <AdminButton
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        CREER PRODUIT
      </AdminButton>
      <AdminButton
        onClick={() => {
          setIsImportModalOpen(true)
        }}
      >
        IMPORT PRODUIT
      </AdminButton>
      <AdminButton onClick={handleExport}>EXPORT PRODUIT</AdminButton>

      <ListProduct productsList={products} />
      <Modal
        isOpen={isModalOpen}
        title='Ajouter un nouveau produit'
        onClose={() => {
          setIsModalOpen(false)
        }}
      >
        <AddProducts
          closeModal={() => {
            setIsModalOpen(false)
          }}
          onAddProduct={handleAddProduct}
        />
      </Modal>
      <Modal
        isOpen={isImportModalOpen}
        title='Import Produit'
        onClose={() => {
          setIsImportModalOpen(false)
          setSelectedFile(null)
        }}
      >
        <div className='flex flex-col items-center justify-center space-y-4'>
          <label
            className={`label ${
              selectedFile ? 'border-green-500' : 'border-gray-300'
            }`}
            htmlFor='file_upload_2'
            onDragOver={onDragOver}
            onDrop={onDrop}
          >
            <span className='span-container'>
              <ImportIcon
                className={`icon ${
                  selectedFile ? 'text-green-600' : 'text-gray-600'
                }`}
              />
              <span
                className={`font-medium ${
                  selectedFile ? 'text-green-600' : 'text-gray-600'
                }`}
              >
                {selectedFile ? selectedFile.name : 'Importer un fichier .xlsx'}
              </span>
            </span>
            <input
              className='hidden'
              name='file_upload_2'
              onChange={handleFileChange}
              type='file'
              id='file_upload_2'
              multiple
            />
          </label>
          <AdminButton
            className='import-products-button'
            onClick={handleUpload}
          >
            Importer
          </AdminButton>
        </div>
      </Modal>

      <InfoModal
        isOpen={isInfoModalOpen}
        title='Import produits'
        message={modalText}
        onClose={() => {
          setIsInfoModalOpen(false)

          if (shouldCloseModal) {
            setIsImportModalOpen(false)
          }
        }}
      />
    </div>
  )
}

export default Products
